import React from "react"

import CategoryPosts from "./CategoryPosts"
import Layout from "../components/Layout"
import Footer from "../components/Layout/Footer"
import DynamicCallToAction from "../components/Layout/Footer/DynamicCallToAction"
import NavWithLogo from "../components/Layout/NavWithLogo"
import SeoWithLanguage from "../components/SeoWithLanguage"
import { QueryWithNodes } from "../types/queries"
import { CategoryPost } from "./types"
import Category from "../types/Category"
import { useRedirectToPageWithSelectedLanguage } from "../utils/redirect"
import { LocalizedContent } from "../types/Localization"
import { getCategoryLink } from "../utils/links"

interface CategoryContent extends Category, LocalizedContent {}

interface Data {
  posts: QueryWithNodes<CategoryPost>
  tag: CategoryContent
  tags: QueryWithNodes<CategoryContent>
}

interface Props {
  data: Data
}

export default function CategoryPage({
  data: {
    tag,
    posts: { nodes: posts },
    tags: { nodes: tags },
  },
}: Props) {
  useRedirectToPageWithSelectedLanguage<CategoryContent>(
    tag,
    tags,
    getCategoryLink
  )

  return (
    <Layout
      footer={<Footer callToAction={<DynamicCallToAction />} />}
      nav={<NavWithLogo />}
    >
      <SeoWithLanguage title="Blog" />
      <CategoryPosts tag={tag} posts={posts} />
    </Layout>
  )
}
