import React from "react"
import {
  withStyles,
  WithStyles,
  createStyles,
  Theme,
  Typography,
} from "@material-ui/core"

const styles = ({ breakpoints: { up } }: Theme) => {
  const xl = up("xl")
  return createStyles({
    root: {
      marginBottom: "3rem",
      [xl]: {
        marginBottom: "4rem",
      },
    },
  })
}

interface Props extends WithStyles<typeof styles> {
  children: React.ReactNode
}

const PostSectionTitle = ({ classes, children }: Props) => {
  return (
    <Typography className={classes.root} variant="h1" component="h2">
      {children}
    </Typography>
  )
}

export default withStyles(styles)(PostSectionTitle)
