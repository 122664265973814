import React from "react"
import { createStyles, withStyles, WithStyles } from "@material-ui/core"

import { PostPreview } from "../types/Post"
import PageContainer from "../components/PageContainer"
import PostSectionTitle from "../components/PostSectionTitle"
import Posts from "../components/Posts"
import { CategoryPost } from "./types"
import Category from "../types/Category"
import { getNodeByIdFinder } from "../utils/find"
import { NodeWithId } from "../types/Nodes"

const styles = () => {
  return createStyles({
    root: {
      marginBottom: "4rem",
    },
  })
}

interface Props extends WithStyles<typeof styles> {
  tag: Category
  posts: CategoryPost[]
}

const CategoryPosts = ({ tag, posts, classes }: Props) => {
  const getWhetherPostHasCategory = (post: CategoryPost): boolean => {
    const matchingTag: NodeWithId | undefined = getNodeByIdFinder<NodeWithId>()(
      tag.id,
      post.tags
    )
    return !!matchingTag
  }

  const getPostsForCategory = (): PostPreview[] => {
    return posts.filter(getWhetherPostHasCategory)
  }

  const postsForCategory: PostPreview[] = getPostsForCategory()

  return (
    <section className={classes.root}>
      <PageContainer>
        <PostSectionTitle>{tag.name}</PostSectionTitle>
        <Posts posts={postsForCategory} />
      </PageContainer>
    </section>
  )
}

export default withStyles(styles)(CategoryPosts)
